import React from "react"

export default function TextFeature(props) {
  return (
    <div class="position-relative gradient-y-gray">
      <div class="container space-2 space-bottom-sm-3">
        <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-1 mb-md-2">
          <b>Standup.  Hosting.  Emceeing.  Keynotes.  Consulting.  Improv.  Panel Moderation.  Idea Generation.  </b>
          <br/><br/>
          <p>
          Rajiv will spice up your day/evening, properly introducing speakers/presenters and sprinkling in witty comments to keep the audience engaged.
          </p>
        </div>
      </div>
      <figure class="position-absolute bottom-0 right-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
        >
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
    </div>
  )
}
