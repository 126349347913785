import React from "react"
import { Link } from "gatsby"
import { useBookingTypes } from "../hooks/useevents"

export default props => {
  const booking_types = useBookingTypes()

  return (
    <div className="container space-2">
      {/* <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <h2>Section Title</h2>
      </div> */}
      <div className="row justify-content-lg-center">
        {booking_types.map((item, key) => (
          <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7" key={key}>
            <div className="media pr-lg-5">
              <figure className="w-100 max-w-8rem mr-4">
                <img
                  className="img-fluid"
                  src={item.icon.asset.fluid.src}
                  alt="SVG"
                />
              </figure>
              <div className="media-body">
                <h4>{item.title}</h4>
                <p>{item.short_description}</p>
                <Link
                  className="btn btn-primary transition-3d-hover mb-1 mb-sm-0 mx-1"
                  to={`/${item.slug.current}`}
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
