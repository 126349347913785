import React from "react"
import Layout from "../components/layout/layout"
import EventServices from "../components/event_services"
import { Helmet } from "react-helmet"
import Stats from "../components/stats"
import Hero from "../components/hero"
import BookingClients from "../components/booking_clients"
import BookingContact from "../components/BookingContact"
import TextFeature from "../components/text_feature"
import { graphql } from 'gatsby'

export default data => {
  const stats = {
    stats: data.data.sanityPages.content[0].stats,
    title: data.data.sanityPages.content[0].stats_title,
    subtitle: data.data.sanityPages.content[0].stats_subtitle,
  }

  const clients_data = {
    title: data.data.sanityPages.content[0].clients_title,
    clients: data.data.sanityPages.content[0].clients,
  }

  return (
    <Layout>
      <Helmet title={`Rajiv Satyal - ${data.data.sanityPages.title}`} />
      <Hero data={data.data.sanityPages} />
      <TextFeature />
      <EventServices />
      <Stats stats={stats} />
      <BookingClients data={clients_data} />
      <BookingContact />
    </Layout>
  )
}

export const query = graphql`
  query BookQuery {
    sanityPages(slug: { current: { eq: "hire" } }) {
      id
      hero_subtitle
      hero_title
      hero_video
      title
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      content {
        ... on SanityBooking {
          _key
          _type
          clients_title
          clients {
            name
            picture {
              asset {
                fluid(maxWidth: 350) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
          stats {
            caption
            number
          }
          stats_subtitle
          stats_title
        }
      }
    }
  }
`
