import { useStaticQuery, graphql } from "gatsby"

export const useBookingTypes = () => {
  const { allSanityBookingTypes } = useStaticQuery(
    graphql`
      query BookingTypesQuery {
        allSanityBookingTypes {
          nodes {
            title
            short_description
            slug {
              current
            }
            icon {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
        }
      }
    `
  )
  return allSanityBookingTypes.nodes
}
