import React from "react"

export default props => {
  return (
    <div className="container space-2">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span className="d-block small font-weight-bold text-cap mb-2">
          {props.stats.subtitle}
        </span>
        <h2>{props.stats.title}</h2>
      </div>
      <div className="w-lg-80 mx-lg-auto">
        <div className="row">
          {props.stats.stats.map((item, key) => (
            <div className="col-sm-3 mb-3 mb-sm-0" key={key}>
              <div className="text-center">
                <span
                  className="d-block font-size-5 font-size-md-down-3 font-weight-bold"
                  style={{ color: "#0088FF" }}
                >
                  {item.number}
                </span>
                <p>{item.caption}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
